@import "./constants/colors";

@import "./navigation/navbar.scss";
@import "./home/landing.scss";
@import "./home/aboutme.scss";
@import "./home/contactme.scss";
@import "./home/portfolio.scss";
@import "./components/card.scss";
@import "./navigation/footer.scss";
@import "./components/forminput.scss";
@import "./components/subtitle.scss";

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}