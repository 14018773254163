.card-container {
    display: table-column; 
    flex-basis: 33.33%;
    padding: 0.75rem;
    text-align: center;
    .card {
        background: white;
        padding: 1rem;
        border-radius: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    } 
    img {
        width: 100%;
        border-radius: 2rem;
    } 
    h2 {
        font-family: 'Comfortaa';
        font-weight: 700;
        color: $color-blue;
        display: inline-block;
        position: relative;
        padding-top: 0.75rem;
        margin: 0;
        
        &::after {
            content: "";
            background-color: $color-peach;
            bottom: 0;
            height: 4px;
            left: 0;
            position: absolute;
            width: 100%;
          }
    }

    p {
        font-family: 'Comfortaa';
    }
}