.landing {
    text-align: center;
    background-color: $color-beige;
}

.image {
   display:flex;
   justify-content: center;

   img {
       border-radius: 50%;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       height: 260px;
       width: 260px;
   }
}

.text {
    font-family: 'Comfortaa';
    color: $color-blue;

    h1 {
        margin: 0;
        padding: 0.25rem;
        font-size: 3.5rem;
    }

    h3 {
        margin: 0;
        padding:0.25rem;
        font-size: 2rem;
    }
  }

.logos {
    display:flex;
    justify-content: space-evenly;
    padding-bottom: 16px;
}