.contactme-container {
  background-color: $color-beige;
  // text-align: center;
  padding: 2rem;
  text-align: center;
  
  h1 {
    display: inline-block;
    position: relative;
    font-family: "Comfortaa";
    font-size: 2.5rem;
    margin: 2rem;

    &::after {
      content: "";
      background-color: $color-peach;
      bottom: 0;
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  .contactme {
    background: white;
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} 
  .description {
    font-family: "Comfortaa";
  }
}


